import { off } from '@/utils/index';
export default {
  data: function data() {
    return {
      chart: null
    };
  },
  methods: {
    resize: function resize() {
      this.chart && this.chart.resize();
    }
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.resize);
  }
};