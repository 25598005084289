// import {
//   getBreadCrumbList,
//   setTagNavListInLocalstorage,
//   getMenuByRouter,
//   getTagNavListFromLocalstorage,
//   getHomeRoute,
//   getNextRoute,
//   routeHasExist,
//   routeEqual,
//   getRouteTitleHandled,
//   localSave,
//   localRead,
//   checkExist
// } from '@/utils/util'
//
// import router from '@/router'
// import routers from '@/router/routers'
// import store from '@/store'
//
// const closePage = (state, route) => {
//   const nextRoute = getNextRoute(state.tagNavList, route)
//   state.tagNavList = state.tagNavList.filter(item => {
//     return !routeEqual(item, route)
//   })
//   router.push(nextRoute)
// }
//
// /**
//  * 校验缓存中的tagNav列表 和 用户拥有的菜单权限列表，去掉没有权限的tagNav
//  * @param {Array} tagNavList
//  */
// const buildInitTagNavList = (tagNavList) => {
//   if (tagNavList.length <= 1) {
//     return tagNavList
//   }
//   const routes = store.state.permission.addRoutes
//
//   const newTagNavList = new Array()
//   const navSet = new Set()
//   tagNavList.forEach(nav => {
//     if (!navSet.has(nav.name) && (nav.name === 'index' || checkExist(routes, nav))) {
//       navSet.add(nav.name)
//       newTagNavList.push(nav)
//     }
//   })
//   return newTagNavList
//
// }
//
// export default {
//   state: {
//     breadCrumbList: [],
//     tagNavList: [],
//     homeRoute: {},
//     local: localRead('local'),
//     errorList: [],
//     hasReadErrorPage: false
//   },
//   getters: {
//     menuList: (state, getters, rootState) => getMenuByRouter(routers),
//   },
//   mutations: {
//     setBreadCrumb (state, route) {
//       state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
//     },
//     setHomeRoute (state, routes) {
//       state.homeRoute = getHomeRoute(routes, homeName)
//     },
//     setTagNavList (state, list) {
//       let tagList = []
//       if (list) {
//         tagList = [...list]
//       } else {
//         tagList = getTagNavListFromLocalstorage() || []
//       }
//
//       if (tagList[0] && tagList[0].name !== homeName) {
//         tagList.shift()
//       }
//
//       const homeTagIndex = tagList.findIndex(item => item.name.split("?")[0] === homeName)
//       if (homeTagIndex > 0) {
//         const homeTag = tagList.splice(homeTagIndex, 1)[0]
//         tagList.unshift(homeTag)
//       }
//       state.tagNavList = buildInitTagNavList(tagList)
//       setTagNavListInLocalstorage([...tagList])
//     },
//     closeTag (state, route) {
//       const tag = state.tagNavList.filter(item => routeEqual(item, route))
//       route = tag[0] ? tag[0] : null
//       if (!route) return
//       closePage(state, route)
//     },
//     addTag (state, { route, type = 'unshift' }) {
//       const router = getRouteTitleHandled(route)
//       if (!routeHasExist(state.tagNavList, router)) {
//         if (type === 'push') {
//           state.tagNavList.push(router)
//         } else {
//           if (router.name === homeName) {
//             state.tagNavList.unshift(router)
//           } else {
//             state.tagNavList.splice(1, 0, router)
//           }
//         }
//         setTagNavListInLocalstorage([...state.tagNavList])
//       }
//     },
//     setLocal (state, lang) {
//       localSave('local', lang)
//       state.local = lang
//     },
//     addError (state, error) {
//       state.errorList.push(error)
//     },
//     setHasReadErrorLoggerStatus (state, status = true) {
//       state.hasReadErrorPage = status
//     }
//   },
//   actions: {}
// }