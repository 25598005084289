// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/ui-components/nav_bg_normal.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-chart-box[data-v-bc2ebbf6] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  height: 19.2vh;\n}\n.page-chart-box .title_img[data-v-bc2ebbf6] {\n  -ms-flex-item-align: start;\n      align-self: flex-start;\n  height: 2.5vh;\n}\n.page-chart-box .tag_list[data-v-bc2ebbf6] {\n  height: 0;\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  padding: 1vh 0;\n  -ms-flex-line-pack: start;\n      align-content: flex-start;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n.page-chart-box .tag_list .tag_item[data-v-bc2ebbf6] {\n  -ms-flex-preferred-size: 50%;\n      flex-basis: 50%;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  height: 3.1vh;\n  margin-bottom: 1vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  font-weight: 400;\n  font-style: italic;\n  color: #92b9fb;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.page-chart-box .tag_list .tag_item[data-v-bc2ebbf6]:nth-of-type(2n) {\n  margin-right: 0;\n}", ""]);
// Exports
module.exports = exports;
