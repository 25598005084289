// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/sales_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-chart-box[data-v-f25dae50] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  padding: 0;\n}\n.page-chart-box .container[data-v-f25dae50] {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-pack: distribute;\n      justify-content: space-around;\n  width: 100%;\n  height: 12.6vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n}\n.page-chart-box .container .item[data-v-f25dae50] {\n  text-align: center;\n}\n.page-chart-box .container .item .title[data-v-f25dae50] {\n  font-size: 1.5vh;\n  font-weight: 400;\n  font-style: italic;\n  color: #ffffff;\n}\n.page-chart-box .container .item .desc[data-v-f25dae50] {\n  font-size: 2.6vh;\n  font-weight: bold;\n  font-style: italic;\n}\n.page-chart-box .container .item .desc .text1[data-v-f25dae50] {\n  color: #f2bc2b;\n}\n.page-chart-box .container .item .desc .text2[data-v-f25dae50] {\n  color: #e34037;\n}", ""]);
// Exports
module.exports = exports;
